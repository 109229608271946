@charset "UTF-8";
/* Color variables */
/* Default colors */
/* Background or border colors */
/* Font color */
/* Font size */
/* Font family */
/* Space width */
/* Other */
/* business */
/*超行溢出显示省略号*/
i {
  font-style: normal; }

.App {
  font-family: PingFangSC-Regular, PingFang SC, Hiragino Sans GB, Arial, sans-serif;
  background-color: #F6F7FB;
  min-height: 100vh;
  font-weight: 600; }

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none; }

.logo {
  position: relative;
  display: block;
  width: 55px;
  height: 55px;
  overflow: hidden;
  border-radius: 19px;
  background-color: #ff6700; }

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white; }

p {
  word-break: break-all; }

.App-link {
  color: #61dafb; }

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

.animated-router {
  /** START: 自定义进出场动画 **/
  /** END **/ }

.animated-router-container {
  /* 动画容器节点 */ }

.animated-router-in-transition {
  /* 页面动画中 */
  position: relative;
  width: 100%;
  overflow: hidden; }

.animated-router-forward-enter {
  transform: translate(100%); }

.animated-router-forward-enter-active {
  transform: translate(0); }

.animated-router-forward-exit {
  transform: translate(0); }

.animated-router-forward-exit-active {
  transform: translate(-100%); }

.animated-router-backward-enter {
  transform: translate(-100%); }

.animated-router-backward-enter-active {
  transform: translate(0); }

.animated-router-backward-exit {
  transform: translate(0); }

.animated-router-backward-exit-active {
  transform: translate(100%); }

.animated-router-forward-enter-active, .animated-router-forward-exit-active, .animated-router-backward-enter-active, .animated-router-backward-exit-active {
  /* 不同过渡阶段需要的过渡时间与缓动效果 */
  transition: transform 0.3s linear; }

.animated-router-forward-exit, .animated-router-backward-exit {
  position: absolute !important;
  width: 100%;
  top: 0;
  left: 0; }
