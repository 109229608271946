@charset "UTF-8";
/* Color variables */
/* Default colors */
/* Background or border colors */
/* Font color */
/* Font size */
/* Font family */
/* Space width */
/* Other */
/* business */
/*超行溢出显示省略号*/
.no {
  box-sizing: border-box;
  padding-top: 40%;
  text-align: center; }
  .no img {
    width: 50%;
    margin-top: -20%; }
  .no .sorry {
    font-size: 14px;
    margin: 0 auto 100px;
    color: rgba(39, 39, 39, 0.6); }
  .no .goBack {
    width: 80%;
    margin: 0 auto;
    border-radius: 4px;
    color: #fff;
    background: linear-gradient(90deg, #986EFF 0%, #6D5CFF 100%); }
