@import '~@csstools/normalize.css';

html {
  font-size: 10px;
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  height: 100%;
}

*::-webkit-scrollbar {

  display: none;
  /*ChromeSafari*/

}

.ab {
  -webkit-touch-callout: none;
  /*系统默认菜单被禁用*/
  -webkit-user-select: none;
  /*webkit浏览器*/
  -khtml-user-select: none;
  /*早期浏览器*/
  -moz-user-select: none;
  /*火狐*/
  -ms-user-select: none;
  /*IE10*/
  user-select: none;
}

#noticeDetail {
  -webkit-touch-callout: auto !important;
  /*系统默认菜单被禁用*/
  -webkit-user-select: auto !important;
  /*webkit浏览器*/
  -khtml-user-select: auto !important;
  /*早期浏览器*/
  -moz-user-select: auto !important;
  /*火狐*/
  -ms-user-select: auto !important;
  /*IE10*/
  user-select: auto !important;
}


input {
  -webkit-user-select: auto !important;
  /*webkit浏览器*/
}

textarea {
  -webkit-user-select: auto !important;
  /*webkit浏览器*/
}

@media screen and (max-width: 360px) {
  html {
    font-size: 9px;
  }
}

@media (min-width: 360px) and (max-width: 600px) {
  html {
    font-size: 10px;
  }
}

@media (min-width: 600px) and (max-width: 1200px) {
  html {
    font-size: 11px;
  }
}

body {
  margin: 0;
  font-size: 1.4rem;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #A1AFC3;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.meshim_widget_components_mobileChatButton_ButtonBar .button_text {
  color: red !important;
  background-color: red !important;
}

ul {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}
